// streight from the static build
// TODO - move to main.js

window.easyDropDownDefaultSettings = {
  cutOff: 10,
  wrapperClass: "dropdown",
  onChange: function (selected) {
    // do something
    $(".dropdown.open").addClass("picked");
    $(".dropdown.focus").addClass("picked");
  },
};
(function ($) {
  var windowEl = $(window);
  var documentEl = $(document);
  var bodyEl = $("body");

  // before foundation is loaded - setup the get a quote reveals
  $("[href='#get-a-quote']").attr("data-open", "reveal-nearest-store").attr("aria-controls", "reveal-nearest-store").attr("aria-haspopups", "true").removeAttr("href");
  documentEl.foundation();

  $(function () {
    // console.log( "ready!" );
    $(".site-header--sticky").sticky({ topSpacing: 0 });

    // $('[data-open="reveal-availability"]').click(); // TODO - remove this
    // $('[data-open="reveal-quote"]').click(); // TODO - remove this
    // $('[data-open="reveal-vat-small"]').click(); // TODO - remove this
    // $('[data-open="reveal-nearest-store"]').click(); // TODO - remove this

    // var $selects = $('.form-bar select');
    var $selects = $(".form-bar select, .reviews-all select, #unit-size__select, #reveal-quote select, .about-you__form select, .book-online__form select, .reveal-nearest-store select, .refer-a-friend__form select");

    $selects.easyDropDown(window.easyDropDownDefaultSettings);

    menuListener();
    // initUnitSizeSlider();
    breakpointListener();
    reviewModalListener();
    selectSizeSelectorTypeModalListener();
    scrollToReviews();
    // up_up_and_away_btn();
    // facebookPixelEventListeners();

    $(".tooltip")
      .on("focus", "input", function () {
        $(this).closest(".tooltip").addClass("open");
      })
      .on("blur", "input", function () {
        $(this).closest(".tooltip").removeClass("open");
      });
  });

  function menuListener() {
    // console.log('menu listener');

    $("#mobile-menu-toggle").on("click", function (e) {
      bodyEl.toggleClass("mobile-menu-is-open");
      return false;
    });

    $("#menu-header-menu").on("click", ">li.menu-item-has-children>a", function (e) {
      // if (Foundation.MediaQuery.atLeast('medium')) {
      //   return false;
      // }

      // console.log('basemenu listener a');
      if ($(this).attr("href") === "#" && Foundation.MediaQuery.atLeast("medium")) return false;

      if (Foundation.MediaQuery.is("small only")) {
        e.preventDefault();
        $(this).toggleClass("is-open");
        $(this).closest("li").siblings("li.menu-item-has-children").find("a").removeClass("is-open");
      }
    });

    documentEl.on(
      {
        mouseenter: function () {
          if (Foundation.MediaQuery.is("small only")) {
            return false;
          }
          $(this).find("a").addClass("is-open").closest("li").siblings("li").find("a").removeClass("is-open");
        },

        mouseleave: function () {
          if (Foundation.MediaQuery.is("small only")) {
            return false;
          }
          $(this).find("a").removeClass("is-open");
        },
      },
      "#menu-header-menu>li.menu-item-has-children"
    );

    $("#menu-header-menu").on("click", ".menu-item-has-children .menu-item-has-children>a", function () {
      if ($(this).parent(".find-a-store__section--clickandstore").length !== 1) {
        $(this).toggleClass("is-open");
        $(this).closest("li").siblings("li").find("a").removeClass("is-open");
        return false;
      }
    });
  }

  function breakpointListener() {
    windowEl.on("changed.zf.mediaquery", function (event, newSize, oldSize) {
      // newSize is the name of the now-current breakpoint, oldSize is the previous breakpoint
      breakpointDoSomething();
    });

    // specific things that can't be done without js
    function breakpointDoSomething() {
      if (Foundation.MediaQuery.atLeast("medium")) {
        // change placeholder text
        $("[data-medium-placeholder]").each(function () {
          $(this).attr("placeholder", $(this).attr("data-medium-placeholder"));
        });
      } else {
        $("[data-placeholder]").each(function () {
          $(this).attr("placeholder", $(this).attr("data-placeholder"));
        });
      }
    }
    breakpointDoSomething();
  }

  function selectSizeSelectorTypeModalListener() {
    $("body").on("click", ".select-size-selector-type", function (e) {
      e.preventDefault();
      $("#select-size-selector-type").foundation("open");
    });
  }

  function reviewModalListener() {
    $(".review__single").on("click", ".read-more", function (e) {
      e.preventDefault();
      var reviewHtml = $(this).closest(".review__single").html();
      $("#full-review").html('<div class="review__single review__single--centered">' + reviewHtml + "</div>");
      $("#reveal-full-review").foundation("open");
    });
    /* $(".review__single").on("click", ".view-reply", function (e) {
      e.preventDefault();
      // change to review + reply var reviewHtml = $(this).closest(".review__single").html();
      $("#full-review").html('<div class="review__single review__single--centered">' + reviewHtml + "</div>");
      $("#reveal-full-review").foundation("open");
    }); */
  }

  function scrollToReviews() {
    $(".to-reviews:not(.reviews-slider .to-reviews)").on("click", function (e) {
      e.preventDefault();
      if ($(".reviews-slider").length == 1) {
        // sticky nav was causing issues, so have to manually set
        var windowWidth = $(window).width();
        var isSticky = $("#sticky-wrapper").hasClass("is-sticky");
        var adminOffset = $("#wpadminbar").length === 1 ? true : false;
        var scrollOffset;

        if (windowWidth < 601) {
          scrollOffset = 62 + (isSticky ? 0 : 67) + 20;
        } else if (windowWidth >= 601 && windowWidth < 640) {
          scrollOffset = 62 + (adminOffset ? 46 : 0) + (isSticky ? 0 : 67) + 20;
        } else if (windowWidth >= 641 && windowWidth < 783) {
          scrollOffset = 84 + (adminOffset ? 46 : 0) + (isSticky ? 0 : 119) + 20;
        } else {
          scrollOffset = 84 + (adminOffset ? 32 : 0) + (isSticky ? 0 : 119) + 20;
        }
        $("html, body").animate(
          {
            scrollTop: $(".reviews-slider").offset().top - scrollOffset,
          },
          1000
        );
      }
    });
  }

  /*
  function up_up_and_away_btn()
  {
    //Check to see if the window is top if not then display button
    windowEl.scroll(function () {
      if ($(this).scrollTop() > 500) {
        $('.up-up-and-away-btn').addClass('hello');
      } else {
        $('.up-up-and-away-btn').removeClass('hello');
      }
    });

    // open modal
    $('.up-up-and-away-btn').on('click', function () {
      // $('html, body').animate({scrollTop : 0},800);
      $('#reveal-nearest-store').foundation('open');
      return false;
    });
  }
  */

  $("button.close-button").on("click", function () {
    $(".reveal-overlay").css("display", "none");
    bodyEl.removeClass("is-reveal-open");
    $("html").removeClass("is-reveal-open");
  });

  $(".readMore").on("click", function (e) {
    e.preventDefault();
    $(this).fadeOut(1000, function () {
      $(this).next(".moreToggle").fadeIn(1000);
    });
  });

  $(".faqModalTrigger").on("click", "a", function (e) {
    e.preventDefault();
    //$('#reveal-faq-choice').foundation('reveal','open');
    var faqChoiceReveal = new Foundation.Reveal($("#reveal-faq-choice"));
    faqChoiceReveal.open();
  });

  // activate reviews slider
  var $reviewsCarousel = $(".reviews-carousel").flickity({
    // options
    cellAlign: "center",
    wrapAround: true,
    groupCells: "80%",
    // adaptiveHeight: true,
    prevNextButtons: false,
    pageDots: false,
  });

  // activate locations slider
  var $locationsCarousel = $(".location-carousel").flickity({
    // options
    contain: true,
    // adaptiveHeight: true,
    prevNextButtons: true,
    pageDots: false,
  });

  //reload flickity after pageload to sort out wp-rocket issues?
  function reloadFlickity() {
    $reviewsCarousel.flickity("resize");
    $locationsCarousel.flickity("resize");
    $(".reviews-slider .review__single__top").matchHeight();
    console.log("reviews & location carousels reloaded");
  }

  $(function () {
    // reload flickity carousels
    setTimeout(reloadFlickity, 2000);
    //fade phone number in to avoid CLS issue
    setTimeout(function () {
      $(".site-header__number").addClass("site-header__number--visible");
    }, 1000);
    setTimeout(function () {
      $(".site-header__mobile-button").addClass("site-header__mobile-button--visible");
    }, 2000);
  });

  // for safari

  // to trigger widow tamer
  // $(window).trigger('resize');

  // modal overlay click fix

  $(".reveal-overlay")
    .unbind("click")
    .on("click", function (e) {
      // to prevent this from being triggered by children
      if (e.target !== this) return;

      $(".reveal-overlay").css("display", "none"), $(document).removeClass("is-reveal-open"), $("html").removeClass("is-reveal-open");
    });

  //mediabox modals
  MediaBox(".mediabox");

  /***
  ********
  ****
  Accordions used on store page banner, but can be used elsewhere too
  ****
  ********
  ***/
  function henfieldAccordions() {
    $(".accordion__title").each(function () {
      var accordion = $(this),
        state = false,
        accordionContent = accordion.next(".accordion__content").hide().css("height", "auto").slideUp();
      accordion.on("click", function () {
        state = !state;
        accordionContent.slideToggle(state);
        accordion.parent().toggleClass("accordion--open", state);
      });
    });
    $(".accordion__switch").on("click", function (e) {
      e.stopPropagation();
    });
  }
  henfieldAccordions();

  /***
  ********
  ****
  Categorised links
  ****
  ********
  ***/
  function henfieldCategorisedLinks() {
    documentEl.on("click", ".categorised-links__category__heading", function () {
      if (window.innerWidth < 1024) {
        $(this).closest(".categorised-links__category").toggleClass("active");
        return false;
      }
    });
  }
  henfieldCategorisedLinks();

  /*$('body').on('mouseover scroll keydown', function(e) {
    $('.static-map__map').hide();
  });*/
})(jQuery); // Fully reference jQuery after this point.

// To avoid widow words, e.g phone number spanning 2 lines
// wt.fix({
// 	elements: '.banner p, .wysiwyg-content p',
// 	chars: 11,
// 	method: 'padding-right',
// 	event: 'resize'
// });

/*
function facebookPixelEventListeners() {
  (function($){
    // top right button
    var quoteBtn = $('#get-a-quote-btn');
    if (quoteBtn && typeof fbq != "undefined") {
      quoteBtn.on('click', function(){
        console.log('FB Event: ViewContent');
        fbq('track', 'ViewContent');
      });
    }
    // form submits
    var quoteForms = $('.home-hero__input-wrap, .form-bar--nearest form, .form-bar--quote form, .reveal-nearest-store form');
    if (quoteForms && typeof fbq != "undefined") {
      quoteForms.submit(function(){
        // only need to check site as this covers manual site selection, and postcode search
        if($(this).find('[name="site"]').val() != ''){
          console.log('=== fbq ===');
          console.log($(this).find('[name="site"]').val())
          console.log('FB Event: ViewContent');
          console.log('=== eof fbq ===');
          fbq('track', 'ViewContent');
        }
      });
    }
    // home hero site dropdown
    var hpSiteDropdown = $('.home-hero__nearest-stores a');
    if (hpSiteDropdown && typeof fbq != "undefined") {
      hpSiteDropdown.on('click', function(){
        console.log('FB Event: ViewContent');
        fbq('track', 'ViewContent');
      });
    }
    var selectAndContinue = $('.button--select-and-continue');
    if (selectAndContinue && typeof fbq != "undefined") {
      selectAndContinue.on('click', function(){
        fbq('track', 'Lead');
      });
    }
    var contactBtn = $('#fbq-contact');
    if (contactBtn && typeof fbq != "undefined") {
      contactBtn.on('click', function(){
        fbq('track', 'Contact');
      });
    }
    var checkAvailabilityBtn = $('#check-availability__btn');
    if (checkAvailabilityBtn && typeof fbq != "undefined") {
      checkAvailabilityBtn.on('click', function(){
        fbq('track', 'InitiateCheckout');
      });
    }
  })(jQuery);
}
*/
